const educationData = [ {
    logo: "/WIN.png",
    school: "School 1",
    degree: "A level",
    fieldOfStudy: "Chemisty, physics, mathematics", 
    startDate: "1988",
    endDate: "1990"
  },
    {
      logo: "/liverpool.png",
      school: "Bachelor of Science (BSc)",
      degree:  "BSc",
      fieldOfStudy: "Chemistry and Pharmacology",
      startDate: "1990",
      endDate: "1993"
    },    {
        logo: "/liverpool.png",
        school: "School 1",
        degree: "PhD",
        fieldOfStudy: "Chemistry and Pharmacology",
        startDate: "1993",
        endDate: "1997"
      },    {
        logo: "/bccancer.png",
        school: "School 1",
        degree: "Postdoctoral",
        fieldOfStudy: "Breast Cancer",
        startDate: "1997",
        endDate: "1999"
      },       {
        logo: "/COE.png",
        school: "School 1",
        degree: "Certificate",
        fieldOfStudy: "Professional Development",
        startDate: "2023",
        endDate: "2023"
      },    {
        logo: "rr.png",
        school: "School 1",
        degree: "Certificate",
        fieldOfStudy: "Climate Adaptation",
        startDate: "2023",
        endDate: "2025"
      },   
    // ... more education objects
  ];
  
  export default educationData;