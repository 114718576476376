import React, { useEffect, useState, useRef } from 'react';
import './PubCardL.css';
import 'animate.css';


export default function PubCardL() {
    const [isVisible, setIsVisible] = useState(false);
    const cardRefL = useRef();
  
    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isVisible) {
            setIsVisible(true);
            observer.unobserve(cardRefL.current); // Unobserve the element after it becomes visible
          }
        });
      });
  
      observer.observe(cardRefL.current);
      return () => observer.unobserve(cardRefL.current); // Cleanup observer on component unmount
    }, [isVisible]); // Depend on isVisible to re-run effect if it changes
  
  return (
    <div ref={cardRefL} className={`pub-cardL ${isVisible ? 'animate__animated animate__delay-1.5s animate__slow animate__backInRight' : 'pub-cardL-hidden'}`}>
    <div className="image-sectionL">
        <div className="background-boxL"></div> {/* New div for background box */}
        <div className="img-containerL">
          <img src="./pp.png" alt="profile pic" />
        </div>
      </div>
      <div className="text-sectionL">
        <div className="title-boxL">
          <h2>Google Scholar</h2>
        </div>
        <div className="comment-boxL"> {/* Added a new div for comment */}
          <p>Publications: 179</p>
          <p>Citations: 6,971</p>
          <p>i10-index: 87</p>
        </div>
        <div className="button-boxL">
          <a href="https://scholar.google.ca/citations?user=UOCF5nEAAAAJ&hl=en&oi=ao" target="_blank" rel="noopener noreferrer">visit profile</a>
        </div>
      </div>
    </div>
  );
}
