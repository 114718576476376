import React, { useEffect, useRef, useState } from 'react';
import ExperienceCard from '../components/ExperienceCard';
import experienceData from '../components/experienceData'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import OverlappingImage from '../components/OverlappingImage';
import './ExperiencePage.css';

export default function ExperiencePage() {
    const [isVisible, setIsVisible] = useState({});
    const observer = useRef(new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setIsVisible(state => ({ ...state, [entry.target.id]: true }));
            }
        });
    }, { threshold: 0.7 }));

    useEffect(() => {
        experienceData.forEach((_, index) => {
            const target = document.getElementById(`item-${index}`);
            if (target) observer.current.observe(target);
        });
        return () => {
            experienceData.forEach((_, index) => {
                const target = document.getElementById(`item-${index}`);
                if (target) observer.current.unobserve(target);
            });
        };
    }, []);

    return (
        <div className="container">
            <div className={window.innerWidth <= 768 ? "mobileExperienceList" : "experienceList"} style={{ justifyContent: experienceData.length < 3 ? 'center' : 'flex-start' }}>
                {experienceData.map((exp, index) => (
                    <div id={`item-${index}`} className="experienceItem" key={index}>
                        <div className={`fadeInFromLeft ${isVisible[`item-${index}`] ? 'visible' : ''}`}>
                            <FontAwesomeIcon icon={faCheckCircle} size="2x" className="icon" />
                        </div>
                        <div className={`fadeIn ${isVisible[`item-${index}`] ? 'visible' : ''}`}>
                            <ExperienceCard 
                                title={exp.title}
                                company={exp.company}
                                startDate={exp.startDate}
                                endDate={exp.endDate}
                                totalTime={exp.totalTime}
                                location={exp.location}
                                details={exp.details}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="imageContainer">
                <OverlappingImage />
            </div>
        </div>
    );
}


