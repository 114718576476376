import React from 'react';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlaskVial } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

export default function Header({style, buttonTextStyle, onClick, currentSection}) {
  return (
      <div className='header' style={style}>
            <div className='titleContainer'>
                <FontAwesomeIcon icon={faFlaskVial} style={logoStyle} />
                Dr. Guns
            </div>
            <div className='buttonContainer'>
          <Button 
            style={currentSection === 'about' ? activeTextStyle(buttonTextStyle) : buttonTextStyle} 
            size="small" 
            onClick={() => onClick('about')}>
            About
          </Button>
          <Button 
            style={currentSection === 'experience' ? activeTextStyle(buttonTextStyle) : buttonTextStyle} 
            size="small" 
            onClick={() => onClick('experience')}>
            Experience
          </Button>
          <Button 
            style={currentSection === 'education' ? activeTextStyle(buttonTextStyle) : buttonTextStyle} 
            size="small" 
            onClick={() => onClick('education')}>
            Education
          </Button>
          <Button 
            style={currentSection === 'publications' ? activeTextStyle(buttonTextStyle) : buttonTextStyle} 
            size="small" 
            onClick={() => onClick('publications')}>
            Publications
          </Button>
          <Button 
              style={currentSection === 'blog' ? activeTextStyle(buttonTextStyle) : buttonTextStyle} 
              size="small" 
              onClick={() => onClick('blog')}>
              Blog
          </Button>

          <Button 
              style={currentSection === 'contact' ? activeTextStyle(buttonTextStyle) : buttonTextStyle} 
              size="small" 
              onClick={() => onClick('contact')}>
              Contact
          </Button>

        </div>
    </div>
  );
}

const logoStyle = {
    marginRight: '10px', // provides some space between the image and text
    fontSize: '2rem', // sets the icon size
};


const activeTextStyle = (buttonTextStyle) => {
    return {
        ...buttonTextStyle,
        fontSize: '1.2rem', // Increase the font size; adjust as necessary
        // Add other styling changes you want for the active section here
    };
};
