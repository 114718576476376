import React, { useEffect, useState, useRef } from 'react';
import './TimelineSmall.css';

const TimelineSmall = ({ logo, school, degree, fieldOfStudy, startDate, endDate }) => {
    const [isVisible, setIsVisible] = useState(false);
    const domRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        if (domRef.current) {
            observer.observe(domRef.current);
        }

        return () => {
            if (domRef.current) {
                observer.unobserve(domRef.current);
            }
        };
    }, []);  

    return (
        <div className={`timeline-small-item ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
            <div className="timeline-small-label left">
                <div className="timeline-small-logo-container">
                    <img src={logo} alt={school} className={logo.includes('liverpool') ? 'invert' : ''} />
                </div>
                <div className="timeline-small-text-container">
                    <h3>{degree}</h3>
                    <p>{fieldOfStudy}</p>
                    <p>{startDate} - {endDate}</p>
                </div>
            </div>
            <div className="timeline-small-dot"></div>
        </div>
    );
};

export default TimelineSmall;
