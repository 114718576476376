import React from 'react';

export default function ExperienceCard({title, company, startDate, endDate, location, details, totalTime}) {
  return (
    <div style={styles.card}>
      <p style={styles.title}>{title}</p>
      <p style={styles.company}>{company}</p>
      <p style={styles.date}>{startDate} - {endDate}, {totalTime}</p>
      <p style={styles.location}>{location}</p>
      <p style={styles.details}>{details}</p>
    </div>
  );
}

const styles = {
  card: {
    margin: '10px',
    backgroundColor: '#F2EDE8',
    // Add more styling as needed
  },
  title: {
    fontSize: '1.5em',
    margin: '0 0 5px',
    color: '#23404F'
  },
  company: {
    fontSize: '1.1em',
    margin: '0 0 5px',
    color: '#23404F'
  },
  date: {
    color: '#23404F',
    margin: '0 0 5px',
    color: '#23404F'
  },
  location: {
    color: '#23404F',
    margin: '0 0 10px',
  },
  details: {
    margin: '0',
    color: '#23404F'
  },
};
