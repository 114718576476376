import React, { useEffect, useState } from 'react';
import TimelineItem from '../components/TimelineItem';
import educationData from './EducationData';
import TimelineSmall from '../components/TimelineSmall';

const styles = {
  timelineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%', 
    height: 'calc(100% - 60px)', // adjust as necessary
  }
};

export default function EducationPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <div style={styles.timelineContainer}>
      {windowWidth >= 1550 ? (
        educationData.slice(0, 6).map((education, index) => (
          <TimelineItem 
            key={index} 
            position={index % 2 === 0 ? 'left' : 'right'} 
            {...education}
          />
        ))
      ) : (
        educationData.slice(0, 6).map((education, index) => (
          <TimelineSmall 
            key={index} 
            position='left'  
            {...education}
          />
        ))
      )}
    </div>
  );
}
