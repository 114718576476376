import React, { useEffect, useState, useRef } from 'react';
import 'animate.css';
import './floatingTitle.css';

export default function FloatingTitle() {
  const [isVisible, setIsVisible] = useState(false);
  const titleRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !isVisible) { // Add a check for the current visibility state
          setIsVisible(true);
          observer.unobserve(titleRef.current); // Unobserve the element immediately after it becomes visible
        }
      });
    });
    observer.observe(titleRef.current);
    return () => observer.unobserve(titleRef.current);
  }, [isVisible]); // Add isVisible as a dependency to the useEffect hook

  return (
    <div ref={titleRef} className={`titleText ${isVisible ? 'animate__animated animate__bounceInDown' : ''}`}>
      My Publications
    </div>
  );
}
