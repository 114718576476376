import React from 'react';
import PubCard from "../components/PubCard";
import PubCardL from "../components/PubCardL";
import './PublicationsPage.css';

export default function PublicationsPage() {
    return (
        <div className='header' style={styles.page}>
            <div className='card-container'>
                <div className='card-right'>
                    <PubCard />
                </div>
                <div className='card-left'>
                    <PubCardL />
                </div>
            </div>
        </div>
    );
}

const styles = {
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', 
        height: '100%',
        backgroundColor: '#F2EDE8',
        margin: '0',
        padding: '0',
        width: '100%',
    },
};
