
import React from 'react';

const OverlappingImage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.square}></div>
      <img src="./pp.png" alt="Overlapping" style={styles.image} />
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    width: '60%', // Adjust the size as needed
    height: '35%',
  },
  square: {
    width: '100%',
    height: '100%',
    backgroundColor: '#D1C0B5',
  },
  image: {
    position: 'absolute',
    top: '-20%', // Adjust as needed
    left: '-20%', // Adjust as needed
    width: '100%', // Adjust the size as needed
  },
};

export default OverlappingImage;
