// experienceData.js
const experienceData = [
    {
      title: 'Independent Consultant',
      company: 'Dr. Emma Guns Science Consulting in Biomedical Science',
      startDate: 'Sep 2019',
      endDate: 'Present',
      totalTime: '4 yrs 1 mo',
      location: 'Vancouver, British Columbia, Canada',
      details: 'Consultant, integrative pharmacologist',
      progress: '100',
    },    
    {
        title: 'Ad hoc Science Consultant',
        company: 'KOP Therapeutics',
        startDate: 'Jul 2019',
        endDate: 'Present',
        totalTime: '4 yrs 3 mos',
        location: 'Vancover, British Columbia, Canada',
        details: 'KOP Therapeutics uncovers groundbreaking technology',
        progress: '100',
      },
      {
        title: 'Film worker’s spouse',
        company: 'Self Employed',
        startDate: 'Mar 1999',
        endDate: 'Present',
        totalTime: '24 yrs 7 mos',
        location: 'Vancouver, British Columbia, Canada',
        details: 'Home support for children and spouse with a career in film',
        progress: '100',
      },
      {
        title: 'Scientific Consultant',
        company: 'Gandeeva Therapeutics',
        startDate: 'May 2022',
        endDate: 'Nov 2022',
        totalTime: '7 mos',
        location: 'Vancouver, British Columbia, Canada',
        details: 'details',
        progress: '100',
      },
      {
        title: 'Scientific Advisory Board',
        company: 'Phoenix Molecular Designs',
        startDate: 'Jan 2015',
        endDate: 'Sep 2019',
        totalTime: '4 yrs 9 mos',
        location: 'Vancouver, British Columbia, Canada',
        details: 'Scientific Advisory Board, Phoenix Molecular Designs, Vancouver, BC, Canada',
        progress: '100',
      },
      {
        title: 'Assistant Professor to Associate Professor',
        company: 'University of British Columbia',
        startDate: 'Jul 2000',
        endDate: 'Aug 2019',
        totalTime: '19 yrs 2 mos',
        location: 'Vancouver, British Columbia, Canada',
        details: 'Faculty of Pharmaceutical Sciences, Vancouver Prostate Centre',
        progress: '100',
      },
      {
        title: 'Scientific Advisory Board Member',
        company: 'Novelogics Biotechnology Inc.',
        startDate: 'Aug 2017',
        endDate: 'Jul 2018',
        totalTime: '1 yr',
        location: 'Vancover, British Columbia, Canada',
        details: 'Provided expert scientific advice for Novelogics drug development program',
        progress: '100',
      },
      {
        title: 'Visiting Professor on Sabbatical',
        company: 'University of Adelaide',
        startDate: 'Jan 2015',
        endDate: 'Jan 2016',
        totalTime: '1 yr 1 mo',
        location: 'Adeleide, Australia',
        details: 'Sabbatical undertaken in Tilley lab, Dame Roma Mitchell Cancer Research Lab.',
        progress: '100',
      },
      {
        title: 'Consultant',
        company: 'Morgan Sports Law',
        startDate: 'Mar 2015',
        endDate: 'Apr 2015',
        totalTime: '2 mos',
        location: 'London, England',
        details: 'Provided expert consultation in a legal case related to sports and doping',
        progress: '100',
      },
      {
        title: 'Consultant',
        company: 'Federal Trade Commission',
        startDate: 'Jun 2008',
        endDate: 'Sep 2008',
        totalTime: '4 mos',
        location: 'Washington, DC',
        details: 'Provided expert scientific consultation regarding product contamination',
        progress: '100',
      },
      {
        title: 'Postdoctoral Research Fellow',
        company: 'BC  Cancer Agency',
        startDate: '1997',
        endDate: '1999',
        totalTime: '2 yrs',
        location: 'Vancouver, British Columbia, Canada',
        details: 'Postdoctoral Fellowship with Dr. Lawrence D. Mayer',
        progress: '100',
      },


  ];
  
  export default experienceData;
  