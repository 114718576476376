import React, { useEffect, useState, useRef } from 'react';
import './TimelineItem.css';

const TimelineItem = ({ logo, school, degree, fieldOfStudy, startDate, endDate, position }) => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (domRef.current) {
        observer.observe(domRef.current);
    }

    // Cleanup function
    return () => {
      if (domRef.current) {
        observer.unobserve(domRef.current);
      }
    };
}, []);  // Empty dependency array means this useEffect runs once when component mounts.


  return (
    <div className={`timeline-item ${isVisible ? 'is-visible' : ''}`} ref={domRef}>
      {position === 'left' && 
        <div className="timeline-label left">
          <div className="logo-container">
          <img src={logo} alt={school} className={logo.includes('liverpool') ? 'invert' : ''} />
          </div>
          <div className="text-container">
            <h3>{degree}</h3>
            <p>{fieldOfStudy}</p>
            <p>{startDate} - {endDate}</p>
          </div>
        </div>}
      <div className="timeline-dot"></div>
      {position === 'right' && 
        <div className="timeline-label right">
          <div className="text-container">
            <h3>{degree}</h3>
            <p>{fieldOfStudy}</p>
            <p>{startDate} - {endDate}</p>
          </div>
          <div className="logo-container">
          <img src={logo} alt={school} className={logo.includes('liverpool') ? 'invert' : ''} />
          </div>
        </div>}
    </div>
  );
};

export default TimelineItem;
