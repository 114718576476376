import React, { useEffect, useState, useRef } from 'react';
import './PubCard.css';
import 'animate.css';

export default function PubCard() {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !isVisible) {
          setIsVisible(true);
          observer.unobserve(cardRef.current); // Unobserve the element after it becomes visible
        }
      });
    });

    observer.observe(cardRef.current);
    return () => observer.unobserve(cardRef.current); // Cleanup observer on component unmount
  }, [isVisible]); // Depend on isVisible to re-run effect if it changes

  return (
    <div ref={cardRef} className={`pub-card ${isVisible ? 'animate__animated animate__delay-1.5s animate__slow animate__backInLeft' : 'pub-card-hidden'}`}>
    <div className="text-section">
        <div className="title-box">
          <h2>ResearchGate</h2>
        </div>
        <div className="comment-box">
          <p>Publications: 115</p>
          <p>Citations: 5,589</p>
          <p>Reads: 45,329</p>
        </div>
        <div className="button-box">
          <a href="https://www.researchgate.net/profile/Emma-Tomlinson-Guns" target="_blank" rel="noopener noreferrer">visit profile</a>
        </div>
      </div>
      <div className="image-section">
        <div className="background-box"></div> {/* New div for background box */}
        <div className="img-container">
          <img src="./pp.png" alt="profile pic" />
        </div>
      </div>
    </div>
  );
}
