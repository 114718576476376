import React, { useRef, useCallback, useState, useEffect } from 'react';
import './Home.css'

import Header from "../components/Header";
import AboutMe from "../components/AboutMe";
import ExperiencePage from "./ExperiencePage";
import EducationPage from "./EducationPage";
import PublicationsPage from './PublicationsPage';
import FloatingTitle from "../components/floatingTitle";
import Blog from './blog';



export default function Home() {
    const aboutRef = useRef(null);
    const experienceRef = useRef(null);
    const educationRef = useRef(null);
    const publicationsRef = useRef(null);
    const blogRef = useRef(null);
    const contactRef = useRef(null);


    const [currentSection, setCurrentSection] = useState(null);
    const handleScroll = useCallback(() => {
        const aboutTop = aboutRef.current?.offsetTop || 0;
        const experienceTop = experienceRef.current?.offsetTop || 0;
        const educationTop = educationRef.current?.offsetTop || 0;
        const publicationsTop = publicationsRef.current?.offsetTop || 0;
        const blogTop = blogRef.current?.offsetTop || 0; // defined this since it was previously undefined
        const contactTop = contactRef.current?.offsetTop || 0;
    
        const scrollPosition = window.scrollY + 80; // Adjust this offset as necessary
    
        if (scrollPosition >= aboutTop && scrollPosition < experienceTop) {
            setCurrentSection('about');
        } else if (scrollPosition >= experienceTop && scrollPosition < educationTop) {
            setCurrentSection('experience');
        } else if (scrollPosition >= educationTop && scrollPosition < publicationsTop) {
            setCurrentSection('education');
        } else if (scrollPosition >= publicationsTop && scrollPosition < blogTop) {
            setCurrentSection('publications');
        } else if (scrollPosition >= blogTop && scrollPosition < contactTop) {
            setCurrentSection('blog');
        } else if (scrollPosition >= contactTop) {
            setCurrentSection('contact');
        }
    }, []); // may need to include refs in dependency array
    
    

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const handleHeaderButtonClick = useCallback((sectionId) => {
        let targetRef;
        switch(sectionId) {
            case 'about':
                targetRef = aboutRef;
                break;
            case 'experience':
                targetRef = experienceRef;
                break;
            case 'education':
                targetRef = educationRef;
                break;
            case 'publications':
                targetRef = publicationsRef;
                break;
                case 'blog':
                    targetRef = blogRef;
                    break;
                case 'contact':
                    targetRef = contactRef;
                    break;
            default:
                console.error('Unknown section:', sectionId);
                return;
        }
        
        if (targetRef && targetRef.current) {
            const offsetTop = targetRef.current.offsetTop;
            window.scrollTo({
                top: offsetTop - 80,  // Adjusts the scroll position 80px above the target section
                behavior: 'smooth'
            });
        }
    }, []); 



    return (
        <div className='homePage'>  
            <Header style={styles.header} buttonTextStyle={styles.buttonText} onClick={handleHeaderButtonClick} currentSection={currentSection} />

            <section ref={aboutRef} className='about-me' >
                <AboutMe />
            </section>

            <section ref={experienceRef} style={styles.ExperiencePage}> 
            <div style={styles.experienceBar}> 
                <p style={styles.experienceText}>Experience</p> 
            </div>
                <ExperiencePage />
            </section>

            {/* Replace the timeline section in Home.js */}
            <section ref={educationRef} style={styles.EducationPage}> 
            <div style={styles.educationBar}>
                <p style={styles.educationText}>Education</p>
            </div>
                <EducationPage />
            </section>


                {/* Include other content related to 'Education' section */}

            <section ref={publicationsRef} style={styles.PublicationsPage}>
                <div style={styles.titleWrapper}>
                    <FloatingTitle />
                </div>
                <PublicationsPage />
                {/* Include other content related to 'Publications' section */}
            </section>

            <section ref={blogRef}  style={styles.BlogPages}>
                <Blog />
                {/* <h2>Courses</h2> */}
                {/* Include other content related to 'Courses' section */}
            </section>
            <section ref={contactRef}>
                {/* <ContactPage /> */}
            </section>
            {/* Add additional sections as needed */}
        </div>
    );
}
const experienceBarHeight = '60px';
const headerHeight = '60px'; 


const colours = {
    white: '#FFFFFF',
    black: '#303630',
    darkblue: '#23404F',
    tan: '#F2EDE8',
    lightblue: '#425A67',
    rose: '#D1C0B5'
    
};

const styles = {
    homePage: {
        position: 'relative',
        width: '80%',
        margin: '0 auto',
        
    },
    header: {
        backgroundColor: colours.tan,
        color: colours.darkblue,
        zIndex: '1000',
    },
    buttonText: {
        color: colours.darkblue,
        fontSize: '1rem', 
        fontFamily: "'EB Garamond', serif"
    }
    ,experienceBar: {
        backgroundColor: colours.darkblue, // Set the background color to dark blue
        height: '60px', // Set height of the bar. Adjust as needed.
        display: 'flex',
        alignItems: 'center', // Center the text vertically
        justifyContent: 'center', // Center the text horizontally
    },
    educationBar: {
        backgroundColor: colours.white, // Set the background color to dark blue
        height: '60px', // Set height of the bar. Adjust as needed.
        display: 'flex',
        alignItems: 'center', // Center the text vertically
        justifyContent: 'center', // Center the text horizontally
    },
    experienceText: {
        color: colours.white, // Set the text color to white
        fontSize: '2rem', // Set font size. Adjust as needed.
        margin: '0', // Remove default margin from p element
        marginBottom: '0.5rem', // Add a small margin below the text. Adjust as needed.
    },
    educationText: {
        color: colours.lightblue, // Set the text color to white
        fontSize: '2rem', // Set font size. Adjust as needed.
        margin: '0', // Remove default margin from p element
        marginBottom: '0.5rem', // Add a small margin below the text. Adjust as needed.
    },
    ExperiencePage: {
        backgroundColor: colours.tan,
        height: `calc(100vh - ${headerHeight} - ${experienceBarHeight})` // subtract the height of the experienceBar
    },
    EducationPage: {
        backgroundColor: colours.lightblue,
        height: `200vh` // subtract the height of the experienceBar
    },
    PublicationsPage: {
        backgroundColor: colours.white,
    },
    BlogPages: {
        backgroundColor: colours.white,
    }
};
