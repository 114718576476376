import React from 'react';
import './AboutMe.css';

export default function AboutMe() {
    return(
        <div className="aboutMeContent">
            <div className="photoContainer">
                <img src="/pp.png" alt="Descriptive Alt Text" className="photo"/>
            </div>
            <div className="textContainer">
            <video autoPlay loop muted playsInline className="video">
                <source src="./leaves.mp4" type="video/mp4" />
            </video>

                <div className="overlay">
                    <div className="title">Dr. Emma Guns</div>
                    <div className="subtext">Independent Business Owner/Operator and Consultant: Integrative Pharmacology, Biomedical Science, Discovery and Communication</div>
                </div>
            </div>
        </div>
    );
}
