import React, { useEffect, useState, useRef } from 'react';
import 'animate.css';
import './blog.css';

export default function Blog() {
  const [isImgVisible, setIsImgVisible] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const imgRef = useRef();
  const textRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === imgRef.current && entry.isIntersecting && !isImgVisible) {
          setIsImgVisible(true);
          observer.unobserve(imgRef.current);
        } else if (entry.target === textRef.current && entry.isIntersecting && !isTextVisible) {
          setIsTextVisible(true);
          observer.unobserve(textRef.current);
        }
      });
    });

    observer.observe(imgRef.current);
    observer.observe(textRef.current);
    return () => {
      observer.unobserve(imgRef.current);
      observer.unobserve(textRef.current);
    };
  }, [isImgVisible, isTextVisible]);

  return (
    <div className='blogPage'>
      <div className='contentWrapperB'>
        <div ref={imgRef} className={`${isImgVisible ? 'imgContainerB animate__animated animate__rotateIn visible' : 'imgContainerB'}`}>
          <img src="/gunsongrugs.png" alt="Descriptive Alt Text" className='imgBlog'/>
        </div>
        <div ref={textRef} className={`${isTextVisible ? 'textContainerB animate__animated animate__fadeIn visible' : 'textContainerB'}`}>
          <p>Check out my blog on linkedIn!</p>
        </div>
      </div>
    </div>
  );
}